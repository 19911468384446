import { useEffect } from 'react';

import { bingInstall } from './install';
export { uetq } from './tracking';

export function BingInstall() {
  useEffect(() => {
    bingInstall();
  }, []);
  return null;
}
